/** @format */

import { Button, Col, Card, Row, Grid } from "antd";
import { useEffect, useRef, useState } from "react";
import responsiveColumn from "../../../methods/responsiveColumn";
import useWindowSize from "../../../methods/useWindowResize";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";

export default function Section_3() {
	const textContainer = useRef(null);
	const textHeight = (textContainer?.current as any)?.offsetHeight;
	const [imageHeight, setImageHeight] = useState<number>(textHeight);
	const { useBreakpoint } = Grid;
	const screenSize = useBreakpoint();
	const size = useWindowSize();

	const { t } = useTranslation("practice_groups_home");
	const practiceGroups = t("practice_groups", {
		returnObjects: true,
	}) as Array<any>;

	const practiceGroupsRight = t("practice_groups_right", {
		returnObjects: true,
	}) as Array<any>;

	const [hasInitiallyRendered, setHasInitiallyRendered] = useState(false);

	useEffect(() => {
		if (!hasInitiallyRendered) setHasInitiallyRendered(true);
		const textHeight = (textContainer?.current as any)?.offsetHeight;
		setImageHeight(textHeight);
	}, [size.width]);

	return (
		<>
			<Row
				justify={"center"}
				gutter={[30, 30]}
				style={{ paddingTop: 30, paddingBottom: 30, lineHeight: 1.5 }}>
				<Col {...responsiveColumn([19, 21, 22, 22, 23, 23])}>
					<Row gutter={[48, 48]} justify='center'>
						<Col style={{ textAlign: "center" }} span={24}>
							<span className='orangeTitle'>
								{hasInitiallyRendered && t("home_section9.section3_orange")}
							</span>
							<h2
								className='section_title'
								style={{
									fontSize: screenSize.md ? 48 : 24,
									marginBottom: screenSize.md ? 10 : -16,
								}}>
								{hasInitiallyRendered && t("home_section9.section3_title")}
							</h2>
						</Col>
						<Row justify={"center"} gutter={[30, 30]}>
							<Col
								{...responsiveColumn(
									[8, 8, 8, 24, 24, 24],
									[1, 1, 1, 2, 2, 2]
								)}
								ref={textContainer}>
								<Row className="how-can-help-box" gutter={[20, 20]}>
									{practiceGroups.map((group: any, index: number) => {
										return (
											<Col span={24} key={index}>
												<Card
													hoverable
													size='small'
													style={{
														boxShadow: "-9px 9px 15px rgb(0 0 0 /60%)",
														paddingLeft: 15,
														paddingRight: 15,
														paddingTop: 15,
														maxWidth: 500,
														margin: screenSize.lg ? "0px auto" : "0px auto",
													}}>
													<Card.Meta
														title={
															<span style={{ fontSize: 24 }}>
																{group.title}
															</span>
														}
														description={
															<span style={{ fontSize: 16 }}>
																{group.description}
															</span>
														}
													/>
													{!group.href ? (
														<Button
															style={{
																float: "left",
																border: "none",
																background: "none",
																padding: 0,
																marginTop: 40,
															}}
															type='link'
															href={group.href}
															key={group.title}
															disabled>
															<span
																style={{
																	float: "left",
																	textDecoration: "underline",
																}}>
																{hasInitiallyRendered &&
																	t("home_section9.section3_coming")}
															</span>
														</Button>
													) : (
														<>
														<Link href={group.href}>
															<Button
																style={{
																	float: "left",
																	border: "none",
																	background: "none",
																	padding: 0,
																	marginTop: 40,
																}}
																type='link'

																key={group.title}>
																<span
																	style={{
																		float: "left",
																		color: "#254486",
																		textDecoration: "underline",
																	}}>
																	{hasInitiallyRendered &&
																		t("home_section9.section3_started")}
																</span>
															</Button>
														</Link>
													</>
													)}
												</Card>
											</Col>
										);
									})}
								</Row>
							</Col>
							<Col
								{...responsiveColumn(
									[8, 8, 8, 24, 24, 24],
									[1, 1, 1, 2, 2, 2]
								)}
								style={{
									color: "#fff",
									lineHeight: 1.5,
									fontSize: 16,
									textAlign: screenSize.lg ? "left" : "center",
								}}>
								<Image
									src='/section_3_hero.jpg'
									height={screenSize.lg ? (imageHeight ?? 0) : 490}
									// style={{ objectFit: "contain" }}
									// //preview={false}
									alt='puzzle'
									width={screenSize.sm ? (screenSize.lg ? 500 : 450) : 345}
									className='imageContainer'
								/>
							</Col>
							<Col
								{...responsiveColumn(
									[8, 8, 8, 24, 24, 24],
									[1, 1, 1, 1, 1, 1]
								)}
								ref={textContainer}>
								<Row className="how-can-help-box" gutter={[20, 20]}>
									{practiceGroupsRight.map((group: any, index: number) => {
										return (
											<Col span={24} key={index}>
												<Card
													hoverable
													size='small'
													style={{
														boxShadow: "-9px 9px 15px rgb(0 0 0 /60%)",
														paddingLeft: 15,
														paddingRight: 15,
														paddingTop: 15,
														maxWidth: 500,
														margin: screenSize.lg ? "0px auto" : "0px auto",
													}}>
													<Card.Meta
														title={
															<span style={{ fontSize: 24 }}>
																{group.title}
															</span>
														}
														description={
															<span style={{ fontSize: 16 }}>
																{group.description}
															</span>
														}
													/>
													{!group.href ? (
														<Button
															style={{
																float: "left",
																border: "none",
																background: "none",
																padding: 0,
																marginTop: 40,
															}}
															type='link'
															href={group.href}
															key={group.title}
															disabled>
															<span
																style={{
																	float: "left",
																	textDecoration: "underline",
																}}>
																{hasInitiallyRendered &&
																	t("home_section9.section3_coming")}
															</span>
														</Button>
													) : (
														<>
														<Link href={group.href}>
															<Button
																style={{
																	float: "left",
																	border: "none",
																	background: "none",
																	padding: 0,
																	marginTop: 40,
																}}
																type='link'

																key={group.title}>
																<span
																	style={{
																		float: "left",
																		color: "#254486",
																		textDecoration: "underline",
																	}}>
																	{hasInitiallyRendered &&
																		t("home_section9.section3_started")}
																</span>
															</Button>
														</Link>
													</>
													)}
												</Card>
											</Col>
										);
									})}
								</Row>
							</Col>
						</Row>
					</Row>
				</Col>
			</Row>
		</>
	);
}
